.noise {
  position: fixed;
  inset: 0;

  z-index: 99999;

  pointer-events: none;

  &::before {
    position: absolute;
    top: -100%;
    left: -50%;
    z-index: 1;

    width: 300%;
    height: 285%;

    background-image: url("../images/noise.png");

    animation: grain 7s steps(10) infinite;

    content: "";
    opacity: .3;
    transition: opacity .4s ease;
  }
}

@keyframes grain {

  0% {
    transform: translate(0);
  }

  10% {
    transform: translate(-5%, -10%);
  }

  20% {
    transform: translate(-15%, 5%);
  }

  30% {
    transform: translate(7%, -25%);
  }

  40% {
    transform: translate(-5%, 25%);
  }

  50% {
    transform: translate(-15%, 10%);
  }

  60% {
    transform: translate(15%);
  }

  70% {
    transform: translate(-5%, -10%);
  }

  80% {
    transform: translate(-15%, 5%);
  }

  90% {
    transform: translate(3%, 35%);
  }

  100% {
    transform: translate(-10%, 10%);
  }
}