.snow {
  position: absolute;

  opacity: 1;

  transition: opacity 300ms ease;

  &--weak {
    opacity: .25;
  }
}