@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: "Söhne";
  font-display: swap;
  src: url("../fonts/soehne-buch.woff2") format("woff2");
}

@font-face {
  font-weight: 400;
  font-style: italic;
  font-family: "Söhne";
  font-display: swap;
  src: url("../fonts/soehne-buch-kursiv.woff2") format("woff2");
}

@font-face {
  font-weight: 300;
  font-style: normal;
  font-family: "Söhne";
  font-display: swap;
  src: url("../fonts/soehne-leicht.woff2") format("woff2");
}

@font-face {
  font-weight: 300;
  font-style: italic;
  font-family: "Söhne";
  font-display: swap;
  src: url("../fonts/soehne-leicht-kursiv.woff2") format("woff2");
}

$font-primary: "Söhne", sans-serif;
