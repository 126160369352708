.button-container {
  position: absolute;
  bottom: 40px;
  left: 50%;

  width: calc(100% - 40px);
  max-width: 400px;

  text-align: center;
  opacity: 0;
  transform: translateX(-50%);
  transition: opacity .6s ease 1s;

  @include breakpoint(md) {
    bottom: 90px;

    max-width: 450px;
  }

  &__button {
    position: absolute;
    bottom: 0;

    display: block;

    font-size: 1em;
    font-weight: $font-weight-light;
    line-height: 1.4;
    color: $color-white;
    text-align: center;
    text-decoration: none;

    border: 1px solid $color-white;
    border-radius: 999px;
    padding: .625em 1.25em .75em;
    opacity: 0;
    pointer-events: none;

    & > span {
      display: block;
    }

    @include breakpoint(md) {
      left: 50%;
      font-size: 1.5em;
      padding: .25em 1.5em .5em;

      transform: translateX(-50%);
      transition: visibility 0s linear .6s, color .3s ease, background-color .3s ease;
    }

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;

      width: 100%;
      height: 100%;

      border-radius: 999px;
      content: "";

      transform: translate(-50%, -50%);
      transition: width 300ms ease, height 300ms ease;
    }

    &::before {
      z-index: -2;
      background-color: rgba($color-dark, .9);
    }

    &::after {
      background-color: $color-white;
      width: 0;
      height: 0;
      z-index: -1;
    }

    &--disabled {
      color: $color-black;
      cursor: default;

      &::after {
        width: 100%;
        height: 100%;
      }
    }

    @media (any-hover: hover) {
      &:hover {
        color: $color-black;

        & > span {
          transform: skewX(-15deg);
        }

        &::after {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &__label {
    font-size: .75em;
    font-weight: $font-weight-light;
    color: $color-white;
    text-align: center;

    margin-bottom: 15px;

    @include breakpoint(sm) {
      font-size: 13px;
      margin-bottom: 20px;
    }
  }

  &__text-sent {
    display: none;
  }

}

.page--transformed {

  .button-container {
    opacity: 1;
  }
}

@for $i from 0 through 10 {

  .page--#{$i} {

    .button-container__button--#{$i} {
      position: relative;
      opacity: 1;
      pointer-events: all;
      transition: opacity .6s ease;

      @include breakpoint(md) {
        transition: opacity .6s ease, visibility 0s linear .6s, color .3s ease, background-color .3s ease;
      }
    }
  }
}