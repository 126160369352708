.intro {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 1;
  transition: opacity .3s linear $intro-show-duration, visibility 0s linear #{$intro-show-duration + .3s};

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 75%;
    transform: translate(-50%, -75%);

    @include breakpoint(md) {
      width: 700px;
    }
  }

  &__subtitle {
    margin-bottom: 1em;
  }
}

.page--transform {

  .intro {
    opacity: 0;
    visibility: hidden;
  }
}