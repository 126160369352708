.heading {
  font-size: 1.5rem;
  font-weight: $font-weight-normal;
  line-height: 1.1;
  color: $color-white;
  text-align: center;

  margin: 0;
  text-shadow: 0 0 20px rgba(0, 0, 0, .1);

  @include breakpoint(sm) {
    font-size: 1.75rem;
  }

  @include breakpoint(md) {
    font-size: 2.25rem;
    line-height: 1.2;
  }

  @include breakpoint(lg) {
    font-size: 3rem;
  }

  @include breakpoint(xxl) {
    font-size: 4rem;
  }

}