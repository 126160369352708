.page {
  position: relative;

  width: 100%;
  height: 100dvh;

  margin: 0;
  overflow: hidden;

  &::before {
    position: absolute;
    top: -50dvh;
    left: -50%;

    width: 200dvw;
    height: 200%;

    background-image: linear-gradient(45deg, $color-black 5%, $color-dark 50%);
    content: "";
  }

  &::after {
    display: none;
    content: "xs";
  }

  &__content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  @include breakpoint(sm) {

    &::after {
      content: "sm";
    }
  }

  @include breakpoint(md) {

    &::after {
      content: "md";
    }
  }

  @include breakpoint(lg) {

    &::after {
      content: "lg";
    }
  }

  @include breakpoint(xl) {

    &::after {
      content: "xl";
    }
  }

  @include breakpoint(xxl) {

    &::after {
      content: "xxl";
    }
  }
}