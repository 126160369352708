.cards-container {
  opacity: 0;

  &__heading {
    position: absolute;
    top: 65px;
    left: 50%;

    width: 300px;

    opacity: 0;
    transform: translateX(-50%);
    transition: opacity .6s ease 1s;
  }

  @include breakpoint(md) {

    &__heading {
      width: 574px;
    }
  }

  @include breakpoint(lg) {

    &__heading {
      width: 90%;
      max-width: 1260px;
    }
  }

  @media (orientation: landscape) and (max-height: 450px) {

    &__heading {
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
    }
  }
}

.page--transform {

  .cards-container {
    opacity: 1;
  }
}

@for $i from 0 through 10 {

  .page--#{$i} {

    .cards-container__heading--#{$i} {
      opacity: 1;
    }
  }
}