

$color-dark: rgb(17, 17, 17);
$color-light: rgb(248, 258, 248);

$color-primary: rgb(255, 136, 67);
$color-primary-1: rgb(255, 136, 67);
$color-primary-2: rgb(255, 114, 114);
$color-primary-3: rgb(227, 45, 136);
$color-primary-4: rgb(102, 87, 183);
$color-primary-5: rgb(76, 91, 231);

:root {
  --color-primary: #{$color-primary};
  --color-primary-1: #{$color-primary-1};
  --color-primary-2: #{$color-primary-2};
  --color-primary-3: #{$color-primary-3};
  --color-primary-4: #{$color-primary-4};
  --color-primary-5: #{$color-primary-5};
}

$color-white: rgb(255, 255, 255);
$color-black: rgb(0, 0, 0);

$font-family: "Söhne";
$font-weight-light: 300;
$font-weight-normal: 400;

$intro-show-duration: 2s;

$rotations: (
  0: 2, 1: -3, 2: -2, 3: 4, 4: 1, 5: -1, 6: -4, 7: 2, 8: 3, 9: -5, 10: 5
);

$breakpoints: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px
);