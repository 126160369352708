.title {
  color: $color-light;

  &--1 {
    font-size: 2.5em;
    font-weight: $font-weight-normal;
    line-height: 1.2;

    margin: 0;

    @include breakpoint(sm) {
      font-size: 3.5em;
    }

    @include breakpoint(md) {
      font-size: 5em;
    }
  }

  &--2 {
    font-size: 1em;
    font-weight: $font-weight-light;
    line-height: 1.2;

    @include breakpoint(sm) {
      font-size: 1.5em;
    }

    @include breakpoint(md) {
      font-size: 2em;
    }
  }
}