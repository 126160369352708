.toggle {
  position: absolute;
  right: 2rem;
  bottom: 2rem;

  display: none;

  @media (any-hover: hover) {

    @include breakpoint(md) {
      display: block;
    }
  }

  &__container {
    transform: rotate(-90deg) translateX(100%);
    transform-origin: bottom right;
  }

  &__switch {
    position: relative;
    display: flex;
    width: 100%;
    height: 18px;

    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }

  &__label {
    font-size: 12px;
    color: $color-light;

    margin-top: -2px;
    padding-left: 45px;
  }

  &__slider {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 35px;

    background-color: rgba($color-dark, .9);
    border: 1px solid $color-white;
    border-radius: 999px;

    transition: background-color 300ms ease;

    &::before {
      position: absolute;
      top: 1px;
      left: 1px;

      width: 14px;
      height: 14px;

      background-color: $color-white;
      border-radius: 999px;

      transition: left 300ms ease, background-color 300ms ease;

      content: "";
    }
  }

  input {
    display: none;

    &:checked {

      & ~ .toggle__slider {

        &::before {
          left: 20px;

          background-color: var(--color-primary);
        }
      }
    }
  }
}