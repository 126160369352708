.cards {
  position: absolute;
  top: 55%;
  left: 50%;
  z-index: 100;

  width: 70%;
  aspect-ratio: 1 / 1;

  transform: translate(-50%, -50%);

  @include breakpoint(sm) {
    top: 50%;

    width: 25rem;
  }

  @include breakpoint(md) {
    width: MIN(35vh, 35rem); // uppercase to force CSS version instead of SCSS version (because parse error)
  }

  @include breakpoint(xxl) {
    top: 55%;
  }

  @media (orientation: landscape) and (max-height: 450px) {
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
  }
}