@mixin breakpoint($breakpoint) {
  @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
    @content;
  }
}

@each $index, $rotation in $rotations {
  @keyframes swipeRight#{$index} {

    0% {
      z-index: 12;
      transform: translateX(6.25rem) rotate(#{$rotation}deg);
    }

    55% {
      transform: translateX(45rem) rotate(#{$rotation}deg);
    }

    100% {
      z-index: 0;
      transform: translateX(0) rotate(#{$rotation}deg);
    }
  }

  @keyframes swipeLeft#{$index} {

    0% {
      z-index: 12;
      transform: translateX(-6.25rem) rotate(#{$rotation}deg);
    }

    55% {
      transform: translateX(-45rem) rotate(#{$rotation}deg);
    }

    100% {
      z-index: 0;
      transform: translateX(0) rotate(#{$rotation}deg);
    }
  }
}

@keyframes shakeLeft {

  10%, 90% {
    transform: translate3d(-1px, -50%, 0) rotate(-90deg);
  }

  20%, 80% {
    transform: translate3d(2px, -50%, 0) rotate(-90deg);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, -50%, 0) rotate(-90deg);
  }

  40%, 60% {
    transform: translate3d(4px, -50%, 0) rotate(-90deg);
  }
}

@keyframes shakeRight {

  10%, 90% {
    transform: translate3d(-1px, -50%, 0) rotate(90deg);
  }

  20%, 80% {
    transform: translate3d(2px, -50%, 0) rotate(90deg);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, -50%, 0) rotate(90deg);
  }

  40%, 60% {
    transform: translate3d(4px, -50%, 0) rotate(90deg);
  }
}