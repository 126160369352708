.link {
  position: absolute;
  top: 35px;
  left: 50%;

  display: inline-block;

  font-size: 12px;
  font-weight: $font-weight-light;
  font-family: $font-family;
  color: $color-white;
  text-decoration: none;
  opacity: 0;

  transform: translateX(-50%);
  transition: opacity .3s ease 1s;

  &--right {
    display: none;
  }

  @include breakpoint(md) {
    top: 50%;

    &--right {
      right: 20px;
      left: auto;

      display: inline-block;
      transform: translateY(-50%) rotate(90deg);

      &:hover {
        animation: shakeRight .82s cubic-bezier(.36, .07, .19, .97) both;
        transform: translate3d(0, -50%, 0) rotate(90deg);
      }
    }

    &--left {
      left: 20px;

      transform: translateY(-50%) rotate(-90deg);

      &:hover {
        animation: shakeLeft .82s cubic-bezier(.36, .07, .19, .97) both;
        transform: translate3d(0, -50%, 0) rotate(-90deg);
      }
    }

    &:hover {
      backface-visibility: hidden;
      perspective: 1000px;
    }
  }

  @media (hover: none) {

    &:hover {
      animation: none;
    }

    &:hover {
      transform: translateX(-50%);
    }

    @include breakpoint(md) {

      &:hover {

        &--left {
          transform: translateY(-50%) rotate(-90deg);
        }

        &--right {
          transform: translateY(-50%) rotate(90deg);
        }
      }
    }
  }
}

.page--transformed {

  .link {
    opacity: 1;

    img {
      width: 5em;
    }
  }
}