.snow-floor {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;

  height: 0;

  background-color: rgba($color-light, 1);

  mix-blend-mode: difference;
  pointer-events: none;

  transition: height ease 500ms;
}