.flake {
  position: absolute;
  pointer-events: none;
  background-color: rgba(var(--dot-color), 1);
  border-radius: 999px;

  animation-duration: 6000ms;
  animation-fill-mode: forwards;
}

@keyframes fall-1 {
  0% {
    transform: translate(0px, 0px) scale(0.5);
  }

  5% {
    transform: translate(7px, -15px) scale(1);
  }


  100% {
    transform: translate(-20px, 100vh) scale(0.5);
    opacity: 0;
  }
}

@keyframes fall-2 {
  0% {
    transform: translate(0px, 0px) scale(0.4);
  }

  7% {
    transform: translate(-9px, -8px) scale(1);
  }

  100% {
    transform: translate(30px, 100vh) scale(0.5);
    opacity: 0;
  }
}

@keyframes fall-3 {
  0% {
    transform: translate(0px, 0px) scale(0.6);
  }

  4% {
    transform: translate(0px, -20px) scale(1);
  }


  100% {
    transform: translate(20px, 100vh) scale(0.5);
    opacity: 0;
  }
}