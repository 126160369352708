.card {
  position: absolute;

  width: 100%;
  box-shadow: 0 4px 8px 0 rgba($color-black, .2), 0 6px 20px 0 rgba($color-black, .19);
  overflow: hidden;
  transition: transform 300ms ease;

  @for $i from 0 through 10 {

    &--#{$i} {
      z-index: #{$i + 2};
      transition-delay: ($intro-show-duration + 1s) + ($i * .1);
    }
  }

  &--0 {
    transform: translate(75vw, 110vh) rotate(20deg);
  }

  &--5 {
    transform: translate(-60vw, -180vh) rotate(85deg);
  }

  &--3 {
    transform: translate(20vw, 180vh) rotate(-40deg);
  }

  &--8 {
    transform: translate(-65vw, 145vh) rotate(110deg);
  }

  &--6 {
    transform: translate(10vw, -170vh) rotate(15deg);
  }

  &--1 {
    transform: translate(-80vw, 105vh) rotate(-65deg);
  }

  &--2 {
    transform: translate(45vw, -180vh) rotate(30deg);
  }

  &--7 {
    transform: translate(-80vw, 125vh) rotate(30deg);
  }

  &--4 {
    transform: translate(90vw, 180vh) rotate(145deg);
  }

  &--9 {
    transform: translate(-85vw, 125vh) rotate(135deg);
  }

  &--10 {
    transform: translate(70vw, 135vh) rotate(-45deg);
  }

  &--current {
    @media (any-hover: hover) {

      &:hover .card__image {
        transform: scale(1.1);
      }
    }
  }

  &, &__image {
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;

    transform-origin: center;
    transition: all 300ms ease;
  }

  &:hover {
    cursor: pointer;
  }
}

.page--transform {

  .card {

    &::before {
      display: none;
    }

    @each $index, $rotation in $rotations {

      &--#{$index} {
        transform: rotate(#{$rotation}deg);

        &::before {
          content: "#{$rotation}";
        }
      }
    }
  }
}

.page--transformed {

  .card {
    transition-delay: 0s;
    transition-duration: 0s;

    @each $index, $rotation in $rotations {

      &--#{$index} {

        &.card--swiperight {
          animation: swipeRight#{$index} .8s;
        }

        &.card--swipeleft {
          animation: swipeLeft#{$index} .8s;
        }
      }

      &--z-#{$index} {
        z-index: $index + 2;
      }
    }
  }
}